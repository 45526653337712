import React, { FunctionComponent } from "react";
import { Link, useI18next } from "../../utils/i18n";
import Clips from "./components/Clips";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useLocation } from "@reach/router";

export type Props = {
  title: string;
  picture: any;
  date: string;
  excerpt: JSX.Element;
  link: string;
};

const HomeLastReport: FunctionComponent<Props> = ({
  picture,
  title,
  date,
  excerpt,
  link,
}) => {
  const { language, t } = useI18next();
  const imageData = getImage(picture) as any;
  const location = useLocation();

  return (
    <div className="relative pt-8 pb-16 md:pb-32">
      <Clips />
      <article className="constrained max-w-[1218px] sm:flex justify-end items-start">
        <div className="relative w-full max-w-[282px] mx-7 xl:ml-[111px] sm:mt-16 sm:mr-8">
          <div className="absolute top-[45px] right-7 xl:top-[65px] xl:right-[65px] w-full lg:w-[calc(100%+46px)] h-full bg-mine-shaft" />
          <Link to={link} state={{ prevPath: location.pathname }}>
            <GatsbyImage
              image={imageData}
              alt={title}
              className="relative z-10"
            />
          </Link>
        </div>
        <div className="relative w-full sm:max-w-[504px] ml-auto mt-24 sm:mt-0">
          <footer className="flex items-center font-bold text-roboto text-12px tracking-wide uppercase">
            <div className="mr-8">{t("home.last-report.heading")}</div>
            <time dateTime={date} className="text-gothic">
              {new Date(date).toLocaleDateString(language, {
                year: "numeric",
                month: "long",
              })}
            </time>
          </footer>
          <header>
            <h1 className="h1 my-8 sm:my-12 font-bold tracking-wide">
              {title}
            </h1>
          </header>
          <div className="whitespace-pre-line text-lg text-abbey">
            {excerpt}
          </div>
          <Link
            to={link}
            state={{ prevPath: location.pathname }}
            className="btn btn-default inline-block mt-8 sm:mt-16 px-20"
          >
            {t("home.last-report.button")}
          </Link>
        </div>
      </article>
    </div>
  );
};

export default HomeLastReport;
