import React, { FunctionComponent } from "react";

export type Props = {
  title: string;
  taglines: Array<string>;
  className?: string;
};

const HomeCard: FunctionComponent<Props> = ({ taglines, title, className }) => (
  <div
    className={`${className} w-[75vw] max-w-[333px] pt-8 pb-6 px-[35px] shadow-card rounded-card bg-white`}
  >
    <h3 className="w-[181px] mx-auto mb-8 text-center uppercase font-bold text-sm tracking-wide text-gothic">
      {title}
    </h3>
    <ul>
      {taglines.map((line) => (
        <li
          key={line}
          className="flex items-center border-t border-mercury py-5 pl-2 pr-6"
        >
          <span style={{ fontSize: 9 }} className="mt-1 shrink-0">
            ●
          </span>
          <h4 className="pl-3 font-medium tracking-wide">{line}</h4>
        </li>
      ))}
    </ul>
  </div>
);

export default HomeCard;
