import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const Factory: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 28 25" className={className}>
    <path
      fill="currentColor"
      d="M10.75 3.42c.21 0 .73.55 1.06 1.15a.55.55 0 00.49.3h.06a.56.56 0 00.3-.13.6.6 0 00.19-.28c.07-.2.64-2.04 0-3.1C12.35.55 11.4.07 10.16 0h-.1a2 2 0 00-1.42.88 1.5 1.5 0 00-.07 1.3c.25.78 1.31 1.24 2.18 1.24zM9.59 1.49a.95.95 0 01.55-.3c.83.05 1.44.34 1.73.8.12.29.16.6.12.9a1.85 1.85 0 00-1.25-.66 1.44 1.44 0 01-1.14-.47.36.36 0 01-.01-.27zM22.64 13.5h-2.36a1.74 1.74 0 00-1.28-.54 1.65 1.65 0 00-1.28.54h-3.03l-.38-6.73a.6.6 0 00-.17-.4.56.56 0 00-.39-.16h-2.41a.56.56 0 00-.37.15.6.6 0 00-.2.36L9.4 16.94l-3.9-2.87a.55.55 0 00-.59-.05.58.58 0 00-.23.21c-.05.1-.08.2-.08.32v2.19L.9 14.06A.55.55 0 00.31 14a.58.58 0 00-.23.22c-.05.1-.08.2-.08.31v8.7c0 .44.17.86.46 1.17.3.3.7.48 1.1.48l11.77.11a1.68 1.68 0 001.25-.52 1.85 1.85 0 00.53-1.3v-2.13L15 19.08l2.77.03c.33.31.77.5 1.22.5.46 0 .9-.17 1.24-.48h1.05c.98 0 1.77 0 1.95.14.1.42.13.84.1 1.27v3.87c0 .15.05.3.16.42.1.1.25.17.4.17h3.55c.15 0 .3-.06.4-.18a.6.6 0 00.16-.41v-3.86c0-4.67-1.8-7.04-5.36-7.04zm-4.1 4.74v-3.87c0-.13.05-.24.14-.32a.44.44 0 01.62 0c.09.08.14.2.14.32v3.87c0 .12-.04.24-.13.33a.44.44 0 01-.32.14.44.44 0 01-.32-.14.49.49 0 01-.13-.33zm-4.58 4.9a.66.66 0 01-.18.47.6.6 0 01-.44.19L1.58 23.7a.44.44 0 01-.32-.14.49.49 0 01-.13-.34l.02-7.54 3.69 2.68a.55.55 0 00.82-.17c.05-.09.08-.2.08-.3v-2.2l3.77 2.78a.55.55 0 00.54.06c.1-.04.17-.1.23-.19a.6.6 0 00.1-.27L11.85 7.4h1.35l.79 13.68-.02 2.07zm.9-6.24l-.13-2.21h2.67v3.23l-2.48-.03-.05-1zm12 6.9h-2.41v-3.26c0-1.13 0-1.8-.56-2.23-.45-.37-1.13-.37-2.62-.37h-.67V14.7h2.04c1.8 0 4.23.61 4.23 5.86v3.26z"
    />
  </svg>
);

export default Factory;
