import React, { FunctionComponent } from "react";

type Props = {
  className: string;
};

const GE: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 40 40" className={className}>
    <path
      fill="currentColor"
      d="M24.5 14.8c0-2.3 1.5-4.5 2.4-4 1 .4-.8 2.5-2.4 4zm-10.5.8c0-1.8 1.8-5.3 3-5 1.2.5-1.2 4-3 5zm-1.8 13.1c-.8 0-1.4-.5-1.4-1.4 0-2.4 3.3-4.6 5.8-5.9-.4 3.4-1.6 7.2-4.4 7.3zm15.2-7.8c-1.9 0-3.3 1.4-3.3 3 0 1.5.8 2.6 2 2.6.3 0 .7-.2.7-.7 0-.8-1-1-.9-2 0-.7.7-1.2 1.4-1.2 1.3 0 2 1.3 2 2.6 0 2-1.6 3.5-3.4 3.5-2.3 0-3.8-2.2-3.8-4.6 0-3.5 2.3-5 3.6-5.3 0 0 3.1.6 3-.8 0-.6-1-.8-1.6-.9-.7 0-1.5.3-1.5.3-.3-.2-.6-.6-.8-1 2.3-1.8 3.9-3.4 3.9-5.3 0-1-.7-1.9-2-1.9-2.2 0-4 2.9-4 5.5 0 .4 0 .9.2 1.3-1.5 1-2.5 1.7-4.5 2.8l.2-1.7c.7-.7 1.6-1.8 1.6-2.6 0-.4-.2-.7-.6-.7-1.2 0-2 1.7-2.2 2.8-.5.6-1.5 1.4-2.3 1.4-.7 0-1-.6-1-.8 2.1-.7 4.7-3.6 4.7-6.2 0-.6-.2-1.8-1.8-1.8-2.5 0-4.6 3.7-4.6 6.6-1 0-1.3-1-1.3-1.7s.3-1.4.3-1.6c0-.3-.1-.5-.4-.5-.9 0-1.4 1-1.4 2.3.1 1.8 1.3 3 2.8 3 .2 1 1.2 2 2.3 2 .8 0 1.6-.1 2.3-.7l-.2 1c-2.5 1.3-4.2 2.2-5.8 3.7a5.8 5.8 0 00-2 3.9c0 1.7 1 3.2 3.2 3.2 2.5 0 4.5-2 5.4-4.9.5-1.3.6-3.2.8-5 2.5-1.4 3.7-2.3 5-3.2l.6.7a7 7 0 00-4 6.3c0 2.9 2 6.1 5.8 6.1 3.2 0 5.3-2.6 5.3-5s-1.2-4.5-3.7-4.5zm9.5 4.8h-.1s.7-2 .6-4.3c0-2.5-1-4-2.2-4-.8 0-1.3.6-1.3 1.4 0 1.5 1.8 1.6 1.8 5a13 13 0 01-.7 3.9c-2 7-8.6 10.1-15 10.1-3 0-5-.6-5.6-.8v-.2c.3 0 2 .7 4.3.7 2.4 0 3.9-1 3.9-2.3 0-.7-.7-1.3-1.4-1.3-1.5 0-1.6 1.8-4.9 1.8-1.3 0-2.6-.2-4-.7-6.9-2-10.1-8.6-10.1-15 0-3.1.8-5.6.9-5.6h.1c0 .3-.7 2-.7 4.3 0 2.4 1 3.9 2.3 3.9.7 0 1.3-.6 1.3-1.4 0-1.5-1.8-1.6-1.8-4.9 0-1.3.3-2.6.7-4 2.1-6.9 8.6-10 15-10.1 3 0 5.6.8 5.7.9v.1h-.1s-1.7-.7-4.3-.7c-2.4 0-3.8 1-3.8 2.3 0 .7.5 1.3 1.3 1.3 1.5 0 1.6-1.8 4.9-1.8 1.3 0 2.6.3 4 .7 7 2.1 10 8.7 10.1 15 0 3.2-.8 5.7-.9 5.7zM20 .9a19 19 0 100 38.2A19 19 0 0020 .9zM20 40a20 20 0 110-40 20 20 0 010 40z"
    />
  </svg>
);

export default GE;
