import React, { FunctionComponent } from "react";

type Props = {
  className: string;
};

const Heineken: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 124 29" className={className}>
    <path
      fill="currentColor"
      d="M124 13.28v.62s-.67.26-.67 1.05v13.2a.84.84 0 01-.83.85h-2.58l-5.68-9s.12.74.12 1.68V29h-3.14V13.28h2.03c1.04 0 1.8.43 2.35 1.3l4.75 7.52s-.15-.46-.15-1.7v-7.12h3.8zm-60.39 0v14.88a.84.84 0 01-.84.84H60.2l-5.68-9s.12.74.12 1.68V29H51.5V13.28h2.03c1.04 0 1.8.43 2.35 1.3l4.75 7.52s-.15-.46-.15-1.7v-7.12h3.14zm-38.9 0h-4.2v6.03h-3.68v-6.03h-4.88v.62s.67.25.67 1.05V29h4.21v-6.82h3.68V29h4.2V13.28zm13.62 0h-9.37v14.31c0 .82.77 1.41 1.58 1.41h7.79c.46 0 .84-.38.84-.85v-2.02H33.8a.64.64 0 01-.63-.64v-3.31h4.78V19.3h-4.79v-3.15h6v-2.02a.85.85 0 00-.83-.85zm8.87 0H43v14.88c0 .46.38.84.84.84h3.36V13.28zm59.61 0h-9.36v14.31c0 .79.77 1.41 1.57 1.41h7.8a.85.85 0 00.83-.85v-2.02h-5.37a.64.64 0 01-.63-.64v-3.31h4.79V19.3h-4.79v-3.15h6v-2.02a.85.85 0 00-.84-.85zm-29.59 0h-9.36v14.31c0 .79.77 1.41 1.58 1.41h7.78a.85.85 0 00.85-.85v-2.02h-5.38a.64.64 0 01-.63-.64v-3.31h4.8V19.3h-4.8v-3.15h6v-2.02a.85.85 0 00-.84-.85zm17.8 0h-3.04c-.73 0-1.29.19-1.87.86l-4.28 4.96v-5.82h-4.2v14.88c0 .46.37.84.83.84h3.37v-7.13l3.7 5.83c.56.87 1.1 1.3 2.37 1.3h3.45l-5.9-9.27"
    />
    <path
      fill="currentColor"
      d="M4.12 19a.26.26 0 01-.4-.29c.1-.35 2.22-6.9 2.22-6.9L.11 7.57a.26.26 0 01.16-.48h7.2L9.7.18a.26.26 0 01.5 0c.1.36 2.23 6.9 2.23 6.9h7.2a.26.26 0 01.16.48L16.3 10.1h-4.12l-1.27-3.94-1.27 3.94H5.53l3.33 2.43-1.27 3.93L4.12 19z"
    />
  </svg>
);

export default Heineken;
