import React, { FunctionComponent } from "react";
import Engie from "./logos/Engie";
import GE from "./logos/GE";
import Heineken from "./logos/Heineken";
import IEA from "./logos/IEA";
import HSBC from "./logos/HSBC";
import Schneider from "./logos/Schneider";
import TotalEnergies from "./logos/TotalEnergies";

export type Props = {};

const HomeClients: FunctionComponent<Props> = () => {
  return (
    <div className="bg-mine-shaft">
      <div className="constrained pb-16 xl:pt-12 flex items-center justify-center flex-wrap text-white">
        <Engie className="m-4 w-[67px] sm:w-[89px]" />
        <GE className="m-4 w-[30px] sm:w-[40px]" />
        <Heineken className="m-4 w-[93px] sm:w-[124px]" />
        <TotalEnergies className="m-4 w-[70px] sm:w-[75px]" />
        <IEA className="m-4 w-[105px] sm:w-[140px]" />
        {/* <HSBC className="m-4 w-[105px] sm:w-[140px]" /> WAITING FOR CLIENT APPROVAL*/}
        <Schneider className="m-4 w-[76px] sm:w-[102px]" />
      </div>
    </div>
  );
};

export default HomeClients;
