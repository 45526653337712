import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const Co2Cloud: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 30 18" className={className}>
    <path
      fill="currentColor"
      d="M9.49 16.88a8.73 8.73 0 009.16-.35 7.22 7.22 0 009.2-.92 7.37 7.37 0 00-5.18-12.56c-.66 0-1.33.1-1.97.3A8.86 8.86 0 008.95 1.4 8.97 8.97 0 005.64 5.2a6.26 6.26 0 00-5.59 5.46 6.33 6.33 0 004.03 6.71 6.25 6.25 0 005.4-.5zm.26-1.11a.58.58 0 00-.28-.08c-.1 0-.21.04-.3.1a5.17 5.17 0 01-8.03-5.05 5.22 5.22 0 014.89-4.47.54.54 0 00.47-.33 7.88 7.88 0 016.32-4.78 7.82 7.82 0 017.25 3.15.54.54 0 00.6.2 6.06 6.06 0 016.42 1.47 6.27 6.27 0 01-4.42 10.7 6.16 6.16 0 01-3.7-1.25.54.54 0 00-.64 0 7.8 7.8 0 01-8.58.34zm.88-7.9c.2-.08.41-.1.62-.1.23 0 .47.04.68.12.2.08.37.17.54.28.12-.07.21-.17.3-.29a.76.76 0 00.12-.43.88.88 0 00-.49-.75c-.43-.22-.9-.32-1.38-.3-.41 0-.82.07-1.21.21A2.72 2.72 0 008.2 8.2a3.5 3.5 0 00-.23 1.33c-.01.48.07.96.24 1.41a2.64 2.64 0 001.67 1.54c.4.13.8.19 1.22.19.46.03.92-.07 1.33-.28a.8.8 0 00.44-.68c0-.15-.03-.3-.1-.43a.87.87 0 00-.25-.33c-.17.1-.35.17-.53.24-.21.08-.44.12-.67.11a1.56 1.56 0 01-1.18-.46 1.78 1.78 0 01-.43-1.3c-.04-.48.1-.94.4-1.32.14-.15.32-.27.51-.34zm3 .31c-.16.43-.24.88-.23 1.34-.01.45.07.9.23 1.33a2.73 2.73 0 001.6 1.59c.4.14.8.21 1.22.2.42.01.83-.06 1.22-.2a2.6 2.6 0 001.61-1.58c.32-.86.32-1.81 0-2.68a2.8 2.8 0 00-1.63-1.57 3.62 3.62 0 00-2.4 0 2.73 2.73 0 00-1.62 1.57zm1.86 2.65a2.09 2.09 0 01-.36-1.31c-.03-.47.1-.93.37-1.31a1.21 1.21 0 01.96-.44 1.17 1.17 0 01.96.44c.26.38.39.85.35 1.31.04.46-.1.93-.36 1.31a1.28 1.28 0 01-1.92 0zm6.67-2.49c-.22-.07-.45-.1-.68-.1-.3-.01-.6.05-.88.17a.55.55 0 00-.33.53c0 .1.03.2.08.28.04.08.1.15.18.2l.36-.17c.16-.06.32-.09.48-.08.15-.01.29.03.41.1a.34.34 0 01.17.3c0 .06-.01.1-.03.16a.61.61 0 01-.1.17l-.19.22c-.1.1-.19.18-.3.26l-.92.8a.87.87 0 00-.21.26.75.75 0 00-.1.4.75.75 0 00.32.64h2.35c.1 0 .2-.04.28-.11.08-.1.12-.23.11-.35a.97.97 0 00-.12-.44h-1.62l.87-.72a1.78 1.78 0 00.8-1.36 1.12 1.12 0 00-.42-.9 1.56 1.56 0 00-.5-.26z"
    />
  </svg>
);

export default Co2Cloud;
