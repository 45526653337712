import React, { FunctionComponent, useState } from "react";
import { topics } from "./utils/constrants";
import Clips from "./components/Clips";
import { useI18next } from "../../utils/i18n";

export type Topic = {
  title: string;
  paragraph: string;
  items: Array<{
    icon: JSX.Element;
    text: string;
  }>;
};

export type Props = {};

const HomeTopics: FunctionComponent<Props> = () => {
  const { t } = useI18next();
  const [selected, setSelected] = useState<number>(0);

  return (
    <section className="relative pt-20 pb-40">
      <Clips />

      <div className="constrained md:flex">
        <div className="relative w-full md:max-w-[282px] md:mr-12">
          <div className="cubicle bg-neon-green" />
          <h2 className="h1 mt-12 mb-16 max-w-[282px] font-bold">
            {t("home.topics.title")}
          </h2>
          <div>
            {topics.map((topic, index) => (
              <button
                key={topic.title}
                onClick={() => setSelected(index)}
                className={`${
                  selected === index
                    ? "text-black font-bold"
                    : "text-abbey font-medium"
                } hidden md:flex flex-row mb-4 text-left`}
              >
                <div
                  className={`${
                    selected === index ? "bg-pastel-green" : "bg-mercury"
                  } w-[5px] min-h-[45px] mr-6 rounded`}
                />
                <h2 className="grow flex items-center justify-center max-w-[172px] text-12px tracking-wide uppercase">
                  {t(topic.title)}
                </h2>
              </button>
            ))}
          </div>
        </div>

        <div className="w-full">
          {topics.map((topic, index) => {
            // md:min-h-[525px] className is just a hack to avoid content jumping on desktop when selecting different topics
            const topicBaseClassName =
              "relative w-full md:min-h-[525px] bg-white py-16 px-7 lg:pl-20 lg:pr-[111px] mb-16 md:mb-0 shadow-card rounded-card";
            return (
              <div
                key={topic.title}
                className={`${topicBaseClassName} ${
                  selected === index ? "xl:flex" : "md:hidden"
                }`}
              >
                <div className="xl:max-w-[308px] xl:mr-8 mb-12 xl:mb-0">
                  <h3 className="font-bold text-2xl pb-6 border-b border-mercury">
                    {t(topic.title)}
                  </h3>
                  <p className="pt-8 text-abbey text-relaxed">
                    {t(topic.paragraph)}
                  </p>
                </div>
                <div className="ml-auto">
                  {topic.items.map((item) => (
                    <div
                      key={item.text}
                      className="flex items-center mb-12 last:mb-0"
                    >
                      <div className="relative flex items-center justify-center w-[55px] h-[55px] shrink-0">
                        <div className="relative z-10 text-pastel-green">
                          {item.icon}
                        </div>
                        <div className="absolute inset-0 rounded bg-alabaster rotate-45" />
                      </div>
                      <h4 className="xl:max-w-[221px] ml-10 font-medium text-lg leading-tight">
                        {t(item.text)}
                      </h4>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default HomeTopics;
