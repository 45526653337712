import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const SolarPanel: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 20 25" className={className}>
    <path
      fill="currentColor"
      d="M19.96 12.86L15.06.39a.6.6 0 00-.23-.28.63.63 0 00-.35-.11H.63a.63.63 0 00-.51.24.59.59 0 00-.09.55l4.3 12.47a.6.6 0 00.23.3c.11.08.24.11.38.1h6.64l-.07 8.32H7.02c-.7 0-1.26.66-1.26 1.52S6.32 25 7.02 25H17.2c.7 0 1.26-.66 1.26-1.52 0-.87-.54-1.5-1.26-1.5h-4.47l.06-8.31h6.6a.62.62 0 00.5-.26.58.58 0 00.07-.55zM17.24 23.5a.5.5 0 01-.08.3H7.08a.55.55 0 01-.1-.32c0-.11.03-.22.1-.31h10.08c.06.1.1.21.08.33zM12.2 12.47h-2L9.1 9.42h3.44l1.14 3.05H12.2zM8.67 8.23L7.59 5.27h3.37l1.11 2.96h-3.4zM2.88 5.27H6.3l1.1 2.96H3.91L2.88 5.27zm6.56-4.05l1.07 2.86H7.16L6.1 1.22h3.33zm2.83 4.07h3.39l1.17 2.96h-3.44L12.27 5.3zm2.94-1.2h-3.4l-1.08-2.85h3.33l1.15 2.86zM4.8 1.23l1.05 2.86H2.48L1.5 1.22h3.31zm-.49 8.22h3.49l1.1 3.06H5.38L4.32 9.44zm10.66 3.06l-1.14-3.06h3.46l1.2 3.06h-3.52z"
    />
  </svg>
);

export default SolarPanel;
