import { useStaticQuery, graphql } from "gatsby";

export type SiteMetaData = {
  siteUrl: string;
};

const useSiteMetadata = (): SiteMetaData => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );
  return site.siteMetadata;
};

export default useSiteMetadata;
