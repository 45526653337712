import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const Battery: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 14 25" className={className}>
    <path
      fill="currentColor"
      d="M12.36 0h1.12c.13 0 .27.06.37.16.1.1.15.24.15.39v23.9c0 .15-.06.29-.15.39-.1.1-.24.16-.37.16H.52a.5.5 0 01-.37-.16.55.55 0 01-.15-.4v-22a.57.57 0 01.15-.39.52.52 0 01.37-.16h7.22V.55A.57.57 0 017.9.16.52.52 0 018.26 0h2.39c.14 0 .27.06.37.16.1.1.15.24.15.39v1.34h.66V.55c0-.15.06-.29.15-.39.1-.1.24-.16.38-.16zm.6 1.1h-.1v.8h.1v-.8zm-2.84 0H8.8v.8h1.33v-.8zM1.05 23.9h11.9V3H1.05v20.9zm10.7-18.7c.08.05.14.13.19.22a.6.6 0 01-.05.59l-3.87 5.92h3.44c.1 0 .2.03.3.09.08.06.15.14.2.24a.6.6 0 01-.1.62L5.08 20.8a.52.52 0 01-.68.1.57.57 0 01-.22-.3.6.6 0 010-.37l1.76-5.05-3.41-.09a.51.51 0 01-.24-.06.56.56 0 01-.28-.42.59.59 0 01.02-.26L4.8 5.39a.58.58 0 01.2-.28c.1-.07.2-.11.32-.11l6.14.1c.1.01.2.04.27.1zm-5.3 7.26c0-.1.05-.2.1-.29l3.89-5.94-4.74-.09-2.43 7.84 3.44.09a.52.52 0 01.25.07c.07.04.14.1.18.18a.58.58 0 01.07.51l-1.06 3.03 4.09-4.78H6.99c-.1 0-.2-.03-.29-.08a.56.56 0 01-.2-.23.6.6 0 01-.05-.3z"
    />
  </svg>
);

export default Battery;
