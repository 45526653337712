import React from "react";
import { PageProps, graphql } from "gatsby";
import { useI18next } from "../utils/i18n";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import HomeJumbotron from "../components/HomeJumbotron";
import Offices from "../components/Offices";
import HomeClients from "../components/HomeClients";
import HomeCategories from "../components/HomeCategories";
import HomeLastReport from "../components/HomeLastReport";
import HomeTopics from "../components/HomeTopics";
import ProBono from "../components/ProBono";
// import HomeTestimonials from "../components/HomeTestimonials";
import HomeProBonoClips from "../components/HomeProBonoClips";
import useSiteMetadata from "../hooks/useSiteMetaData";
import BlunomyBanner from "../components/BlunomyBanner";

type DataProps = {
  testimonials: any;
  lastReport: any;
};

const IndexPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { siteUrl } = useSiteMetadata();
  const { t } = useI18next();
  // const testimonials = data.testimonials.edges;
  const lastReport = data.lastReport.edges[0].node;

  return (
    <Layout>
      <SEO
        title={t("home.meta.title")}
        description={t("home.meta.description")}
        image={`${siteUrl}/assets/open-graph/home-page.jpg`}
      />
      <BlunomyBanner />
      <HomeJumbotron />
      <HomeClients />
      <HomeCategories />
      <HomeLastReport
        title={lastReport.frontmatter.title}
        date={lastReport.frontmatter.date}
        excerpt={lastReport.frontmatter.excerpt}
        link={lastReport.fields.slug}
        picture={
          lastReport.frontmatter.picture || lastReport.frontmatter.main_document
        }
      />
      <HomeTopics />
      <div className="relative">
        <HomeProBonoClips />
        <ProBono title={t("home.pro-bono.title")}>
          {t("home.pro-bono.paragraph")}
        </ProBono>
      </div>
      {/*<HomeTestimonials testimonials={testimonials} />*/}
      <Offices />
    </Layout>
  );
};

export const query = graphql`
  query ($locale: String!) {
    lastReport: allMarkdownRemark(
      filter: {
        fields: { lang: { in: [$locale, "en"] } }
        frontmatter: { type: { eq: "publication" } }
      }
      sort: {
        order: [ASC, DESC]
        fields: [fields___isdefaultLanguage, frontmatter___date]
      }
      limit: 1
    ) {
      edges {
        node {
          ...homePublication
        }
      }
    }
    testimonials: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: { eq: "testimonial" }
          language: { in: [$locale, "en"] }
        }
      }
      sort: { fields: frontmatter___isdefaultLanguage, order: ASC }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            occupation
            picture {
              childImageSharp {
                gatsbyImageData(width: 100, height: 100, layout: CONSTRAINED)
              }
            }
            language
          }
          html
        }
      }
    }
  }
`;

export default IndexPage;
