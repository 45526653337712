import React, { FunctionComponent } from "react";
import { ROUTES } from "../../constants";
import { useI18next } from "../../utils/i18n";
import hero from "!!svg-url-loader!../../images/HOME.svg";

export type Props = {};

const HomeJumbotron: FunctionComponent<Props> = () => {
  const { language, t } = useI18next();

  return (
    <div className="bg-mine-shaft">
      <div className="constrained relative pt-12 pb-32 lg:py-40">
        <h1 className="h1 relative z-10 max-w-sm md:max-w-screen-sm mb-6 text-white font-bold tracking-wide">
          {t("home.jumbotron.title")}
        </h1>
        <p className="relative z-10 max-w-[393px] mb-12 text-silver text-lg">
          {t("home.jumbotron.paragraph")}
        </p>
        <div className="relative z-10 flex">
          <a className="btn btn-primary" href={ROUTES.contact.url()}>
            {t("home.jumbotron.contact")}
          </a>
          <a
            className="btn btn-primary-inverted ml-4"
            href={ROUTES.careers.url(language)}
            target="_blank"
            rel="noreferrer noopener"
          >
            {t("home.jumbotron.careers")}
          </a>
        </div>
        <img
          src={hero}
          alt="Energy transition"
          className="lg:absolute top-[50%] right-ends w-full lg:w-[50%] xl:w-[50.4166%] mt-12 lg:mt-0 lg:-translate-y-[50%]"
        />
      </div>
    </div>
  );
};

export default HomeJumbotron;
