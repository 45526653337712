import React, { FunctionComponent } from "react";
import { useI18next } from "../../utils/i18n";

import HomeCard from "../HomeCard";
import Clips from "./components/Clips";

export type Props = {};

const HomeCategories: FunctionComponent<Props> = () => {
  const { t } = useI18next();

  return (
    <section className="relative">
      <Clips />
      <div className="constrained pt-[140px] pb-12 xl:flex xl:justify-between">
        <div>
          <div className="cubicle bg-neon-green" />
          <h2 className="h1 mt-12 mb-6 lg:mb-12 xl:max-w-[393px] font-bold">
            {t("home.categories.title")}
          </h2>
          <p className="mb-12 xl:max-w-[393px] text-lg text-abbey">
            {t("home.categories.paragraph")}
          </p>
        </div>

        <div className="flex items-start xl:flex-wrap xl:justify-start xl:w-[725px] overflow-x-auto xl:overflow-x-visible py-12 px-5 -mx-5 lg:px-ends lg:-mx-ends xl:px-0 xl:mx-0">
          <div className="pr-4 xl:pr-[59px] mb-16 xl:mb-0 shrink-0">
            <HomeCard
              title={t("home.categories.card1.title")}
              taglines={[
                t("home.categories.card1.text1"),
                t("home.categories.card1.text2"),
                t("home.categories.card1.text3"),
                t("home.categories.card1.text4"),
              ]}
            />
          </div>
          <div className="pr-4 xl:pr-0 xl:pt-[89px] mb-16 xl:-mb-[89px] shrink-0">
            <HomeCard
              title={t("home.categories.card2.title")}
              taglines={[
                t("home.categories.card2.text1"),
                t("home.categories.card2.text2"),
                t("home.categories.card2.text3"),
                t("home.categories.card2.text4"),
              ]}
            />
          </div>
          <div className="pr-4 xl:pr-0 mb-16 xl:mb-0 xl:mt-8 shrink-0">
            <HomeCard
              title={t("home.categories.card3.title")}
              taglines={[
                t("home.categories.card3.text1"),
                t("home.categories.card3.text2"),
                t("home.categories.card3.text3"),
                t("home.categories.card3.text4"),
              ]}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeCategories;
