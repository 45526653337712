import React from "react";
import { Topic } from "../index";
import Sun from "../../Icons/Sun";
import Cook from "../../Icons/Cook";
import Leaf from "../../Icons/Leaf";
import Recycle from "../../Icons/Recycle";
import Battery from "../../Icons/Battery";
import Factory from "../../Icons/Factory";
import Valves from "../../Icons/Valves";
import Co2Cloud from "../../Icons/Co2Cloud";
import PowerUp from "../../Icons/PowerUp";
import PowerTruck from "../../Icons/PowerTruck";
import SolarPanel from "../../Icons/SolarPanel";

const topic1 = {
  title: "home.topics.card1.title",
  paragraph: "home.topics.card1.paragraph",
  items: [
    {
      text: "home.topics.card1.item1",
      icon: <Sun className="h-[25px]" />,
    },
    {
      text: "home.topics.card1.item2",
      icon: <Cook className="h-[25px] relative bottom-[3px]" />,
    },
    {
      text: "home.topics.card1.item3",
      icon: <Leaf className="h-[25px]" />,
    },
    {
      text: "home.topics.card1.item4",
      icon: <Recycle className="h-[25px]" />,
    },
  ],
};

const topic2 = {
  title: "home.topics.card2.title",
  paragraph: "home.topics.card2.paragraph",
  items: [
    {
      text: "home.topics.card2.item1",
      icon: <Battery className="h-[25px]" />,
    },
    {
      text: "home.topics.card2.item2",
      icon: <Factory className="h-[25px]" />,
    },
    {
      text: "home.topics.card2.item3",
      icon: <Valves className="h-[25px]" />,
    },
  ],
};

const topic3 = {
  title: "home.topics.card3.title",
  paragraph: "home.topics.card3.paragraph",
  items: [
    {
      text: "home.topics.card3.item1",
      icon: <Co2Cloud className="h-[18px]" />,
    },
    {
      text: "home.topics.card3.item2",
      icon: <PowerUp className="h-[25px]" />,
    },
    {
      text: "home.topics.card3.item3",
      icon: <PowerTruck className="h-4" />,
    },
    {
      text: "home.topics.card3.item4",
      icon: <SolarPanel className="h-[25px]" />,
    },
  ],
};

export const topics: Array<Topic> = [topic1, topic2, topic3];
