import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const PowerUp: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 25 25" className={className}>
    <path
      fill="currentColor"
      d="M17.355.977a12.473 12.473 0 015.523 4.548 12.504 12.504 0 01-3.396 17.34A12.398 12.398 0 0112.528 25c-.814 0-1.627-.08-2.426-.236a12.504 12.504 0 01-9.082-7.317 12.483 12.483 0 01.917-11.622l-.91.108A.615.615 0 01.864 4.72l4.922-.654a.61.61 0 01.694.526l.654 4.918a.614.614 0 01-.526.688h-.084a.61.61 0 01-.61-.53L5.722 8.19a8.016 8.016 0 001.205 10.062A8.03 8.03 0 0020.2 14.757a8.016 8.016 0 00-3.914-9.349 8.032 8.032 0 00-5.323-.797.616.616 0 01-.886-.841l1.088-1.387-1.418-1.087A.61.61 0 0110.24.209a12.484 12.484 0 017.115.768zm-6.118 22.688a11.282 11.282 0 007.556-1.817A11.268 11.268 0 0023.16 8.843a11.267 11.267 0 00-4.432-5.73 11.281 11.281 0 00-7.007-1.851l.684.536a.615.615 0 01.103.865l-.462.59a9.267 9.267 0 019.251 6.327 9.25 9.25 0 01-3.626 10.6 9.177 9.177 0 01-5.158 1.574 9.267 9.267 0 01-8.417-5.394A9.25 9.25 0 015.49 6.465l-.147-1.097-1.737.231a11.261 11.261 0 00.693 14.565 11.278 11.278 0 006.938 3.5zm.011-8.516l-2.229-.054a.62.62 0 01-.58-.806l2.066-6.29a.62.62 0 01.596-.423l4.557.074a.615.615 0 01.604.657.625.625 0 01-.107.311l-2.648 3.817h2.141a.615.615 0 01.458 1.028L11.08 19.03a.616.616 0 01-1.033-.625l1.2-3.256zm3.249-6.29l-2.953-.049-1.678 5.085 2.263.054a.612.612 0 01.561.827l-.393 1.077 1.968-2.179h-1.929a.62.62 0 01-.546-.33.624.624 0 01.04-.634l2.667-3.85z"
    />
  </svg>
);

export default PowerUp;
