import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const Cook: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 22 26" className={className}>
    <path
      fill="currentColor"
      d="M5.4 5.8a.6.6 0 000 .8l.4.1a.6.6 0 00.5-.2c1-1.3.3-2.1 0-2.6-.4-.5-.6-.6 0-1.3a.6.6 0 00-.5-1l-.4.3C4.3 3.3 5 4 5.4 4.6s.5.6 0 1.2zm10.2 0a.6.6 0 000 .8l.4.1a.6.6 0 00.5-.2c1-1.3.3-2.1 0-2.6-.4-.5-.6-.6 0-1.3a.6.6 0 00-.5-1c-.2 0-.3.2-.4.3-1.1 1.4-.4 2.2 0 2.7s.5.6 0 1.2zm-4.8-1.7a.6.6 0 00.1.8.6.6 0 00.8 0c1-1.3.4-2.2 0-2.6-.4-.5-.5-.7 0-1.4a.6.6 0 00-.7-.8l-.2.1c-1.1 1.4-.4 2.3 0 2.8s.5.5 0 1.1zM21 13.5c-.5-3.5-4.6-6.3-9.7-6.3S2 10 1.6 13.4A1.6 1.6 0 000 15v.1a1.6 1.6 0 001.5 1.6v7A2.3 2.3 0 003.8 26h14.8a2.3 2.3 0 002.4-2.3v-7.1a1.6 1.6 0 001-1.5V15a1.6 1.6 0 00-1-1.5zm-9.7-5.1c4.4 0 8 2.2 8.5 5H2.7c.5-2.8 4.1-5 8.6-5zM1 15a.5.5 0 01.5-.6l19 .1a.5.5 0 01.3.4v.1a.5.5 0 01-.3.4h-19A.5.5 0 011 15zm1.5 8.6v-7h7l-.1.2a.6.6 0 00.5.6h.7v7.4h-7a1.2 1.2 0 01-1.1-1.2zm17.3 0a1.2 1.2 0 01-1.3 1.2h-6.8v-7.4h.5a.6.6 0 00.6-.6v-.2h7v7z"
    />
  </svg>
);

export default Cook;
