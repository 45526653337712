import React, { FunctionComponent } from "react";
import {
  clips,
  clipsContainer,
  clip1,
  clip2,
  clip3,
  clip4,
  clip5,
  clip6,
} from "./index.module.css";

export type Props = {};

const Clips: FunctionComponent<Props> = () => (
  <div className={clips}>
    <div className={clipsContainer}>
      <div className={clip1} />
      <div className={clip2} />
      <div className={clip3} />
      <div className={clip4} />
      <div className={clip5} />
      <div className={clip6} />
    </div>
  </div>
);

export default Clips;
