import React, { FunctionComponent } from "react";
import illustration from "../../images/PRO BONO.svg";

export type Props = {
  title: string;
  className?: string;
};

const ProBono: FunctionComponent<Props> = ({ className, title, children }) => (
  <div className={`${className} constrained`}>
    <div className="md:flex items-center justify-between bg-black px-7 md:pl-0 md:pr-[9%] rounded-card text-white">
      <div className="overflow-hidden flex-1 md:mx-[61px]">
        <img
          src={illustration}
          alt=""
          className="w-full mt-16 md:mt-0 md:scale-[1.19] max-h-[420px]"
        />
      </div>
      <div className="md:mt-0 py-16 md:w-[250px] lg:w-[332px] shrink-0">
        <h3 className="h2 mb-6 font-bold tracking-wide">{title}</h3>
        <p className="text-mercury leading-6">{children}</p>
      </div>
    </div>
  </div>
);

export default ProBono;
