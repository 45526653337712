import React, { FunctionComponent } from "react";
import { clips, clipsContainer, clip1 } from "./index.module.css";

export type Props = {};

const Clips: FunctionComponent<Props> = () => (
  <div className={clips}>
    <div className={clipsContainer}>
      <div className={clip1} />
    </div>
  </div>
);

export default Clips;
