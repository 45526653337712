import React from "react";
import clsx from "clsx";
import { useI18next } from "../../utils/i18n";

type Props = {
  className?: string;
};

export default function BlunomyBanner({ className }: Props): JSX.Element {
  const { t } = useI18next();

  return (
    <div className={clsx(className, "bg-mine-shaft ")}>
      <div className="constrained">
        <div className="flex flex-wrap flex-col lg:flex-row  text-white lg:items-center gap-x-[40px] border-b-2 border-neon-green pb-2">
          <p className="text-krona text-[20px] flex-grow">
            {t("blunomy.title")}
          </p>
          <p className="text-lg py-2">
            <a href="https://theblunomy.com/">{t("blunomy.link")} &#x2192;</a>
          </p>
        </div>
      </div>
    </div>
  );
}
