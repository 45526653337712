import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const Leaf: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 19 25" className={className}>
    <path
      fill="currentColor"
      d="M18.73 23.84c-1.54-1.14-3-2.4-4.36-3.74 1.98-2.47 1.95-6.92-.33-10.98C11.38 4.36 1.84.22 1.42.05A.68.68 0 00.6.3a.64.64 0 00-.1.28C.43 1-1.1 11.07 1.56 15.83a13.16 13.16 0 005.27 5.28 7.6 7.6 0 003.48.92 5.35 5.35 0 003.13-1c1.4 1.38 2.9 2.67 4.5 3.84a.67.67 0 00.93-.13.64.64 0 00-.14-.9zm-6.54-3.57c-1.31.7-3 .6-4.77-.3a11.9 11.9 0 01-4.72-4.78C.66 11.56 1.36 4.13 1.67 1.6c2.35 1.1 9.15 4.51 11.18 8.14 2 3.53 2.12 7.35.57 9.43a63.5 63.5 0 01-6.73-8.26.65.65 0 00-.42-.3.68.68 0 00-.71.26.64.64 0 00.01.74 62.4 62.4 0 006.9 8.47c-.1.06-.18.13-.28.18z"
    />
  </svg>
);

export default Leaf;
