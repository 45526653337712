import React, { FunctionComponent } from "react";

type Props = {
  className: string;
};

const Engie: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 89 30" className={className}>
    <path
      fill="url(#a)"
      d="M89 13.6C77.4 5.2 61.7 0 44.5 0S11.6 5.2 0 13.6h89z"
    />
    <path
      fill="currentColor"
      d="M38 16c-.8 0-1.3.7-1.4 1.6 0 .9-.6 4-.7 4.8 0 .5 0 1-.5 1.2-.5.2-1 0-1.5-.5l-7.7-6.5a2.1 2.1 0 00-1.4-.5c-1 0-2.1.6-2.1 2.3v10.1c0 .8.5 1.3 1.2 1.3s1.2-.5 1.3-1.2c0 0 .8-4.8 1-5.3 0-.7.1-1.2.7-1.4.6-.1 1 .2 1.9.9l7.2 6.5c.5.4.8.6 1.5.6.6 0 1.8-.3 1.8-2.2V17.5c0-1-.6-1.4-1.3-1.4zM84.1 27c-5.6 1.2-12-.1-12-4 0-3 2.8-4.3 5-4.5 2.3-.2 4 .3 5 1.3.6.6.4 1.4-.7 1.5l-4.7.2c-1 0-1.4.5-1.4 1.2 0 .5.4 1 1.5 1l7.3.1c1.3 0 1.9-.7 1.9-2.1 0-2.4-2.1-5.9-8.2-5.9-5.6 0-9.6 2.8-9.6 7.3 0 6.4 9.4 8.4 16.4 6 .6-.2 1.2-.7 1-1.4-.2-.6-.7-.9-1.5-.7zm-65.1 0c-5.6 1.2-12-.1-12-4 0-3 2.7-4.3 4.9-4.5 2.3-.2 4.1.3 5 1.3.6.6.4 1.4-.7 1.5l-4.6.2c-1 0-1.5.5-1.5 1.2 0 .5.4 1 1.5 1l7.4.1c1.2 0 1.9-.7 1.9-2.1 0-2.4-2.1-5.9-8.3-5.9C7 15.7 3 18.5 3 23c0 6.4 9.5 8.4 16.4 6 .7-.2 1.3-.7 1-1.4-.1-.6-.6-.9-1.4-.7zm43.5-6.2c0 3.5.4 6.4.5 7.4.1 1.3.8 1.7 1.5 1.7.6 0 1.3-.3 1.5-1.6l.6-8c0-.2-.2-.3-.3-.3l-3.7.6a.2.2 0 00-.1.2zm2-5c-1.7 0-2.2 1.3-2.1 2.2v1c0 .1.1.3.3.2l3.8-.6a.2.2 0 00.2-.2V18c0-.8-.5-2.2-2.2-2.2zm-5.6 5.6h-7.7c-1.2 0-1.5.7-1.5 1.3 0 .4.3 1 1.5 1.1l4.9.2c1.4.1 1.4.8 1 1.4-1 1.2-3.3 2-5.6 2-3 0-6.5-1.7-6.5-4.5 0-2.6 3-5.4 11.5-4.2.8 0 1.5 0 1.7-.8.2-.7-.3-1.2-1.3-1.5a20.8 20.8 0 00-5.4-.6c-5.7 0-10.4 2.8-10.4 7.2 0 4.8 4.6 7.1 10.4 7.1 6.4 0 9.5-3.5 9.5-6.5 0-1.6-.8-2.2-2.1-2.2z"
    />
    <defs>
      <linearGradient
        id="a"
        x1="44.5"
        x2="44.5"
        y1="13.7"
        y2="0"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="currentColor" stopOpacity="0" />
        <stop offset="1" stopColor="currentColor" />
      </linearGradient>
    </defs>
  </svg>
);

export default Engie;
