import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const PowerTruck: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 32 16" className={className}>
    <path
      fill="currentColor"
      d="M23.27.6h6.13a2.62 2.62 0 012.4 1.57c.13.31.2.65.2.98v8.41c0 .6-.24 1.17-.66 1.59-.43.42-1 .65-1.6.65H28a2.73 2.73 0 01-2.72 2.2 2.79 2.79 0 01-2.71-2.2h-9.13a2.73 2.73 0 01-2.72 2.2 2.79 2.79 0 01-2.71-2.2h-.43A2.73 2.73 0 014.88 16a2.79 2.79 0 01-2.72-2.2H.81a.57.57 0 01-.4-.16.56.56 0 01-.17-.39L.2 10.57a.52.52 0 01.25-.46C.15 9.7 0 9.25 0 8.76V2.24c0-.6.24-1.17.66-1.58.43-.42 1-.66 1.6-.66h17.3c.6 0 1.17.24 1.6.66.42.41.65.98.65 1.58v6.52c0 .43-.12.84-.34 1.2h.74a.5.5 0 00.35-.15.5.5 0 00.15-.35V1.15c0-.15.06-.3.16-.4.1-.1.25-.16.4-.16zM7.57 12.66H8v.01c.14-.63.49-1.2 1-1.6H6.58c.5.4.86.96 1 1.59zM1.45 1.43c-.22.22-.34.5-.34.8v6.53A1.16 1.16 0 002.26 9.9h17.28a1.13 1.13 0 001.14-1.14V2.24c0-.3-.12-.6-.33-.8-.22-.22-.5-.34-.81-.34H2.26c-.3 0-.6.12-.81.33zm1.68 9.7h-1.8l.01 1.58h.8c.14-.62.49-1.18 1-1.58zm.82 3.48c.27.18.59.27.91.27v-.02a1.61 1.61 0 001.64-1.6 1.6 1.6 0 00-1-1.5 1.66 1.66 0 00-1.8.36 1.61 1.61 0 00.25 2.5zm5.86 0c.27.18.58.27.9.27v-.02a1.61 1.61 0 001.64-1.62 1.6 1.6 0 00-1.02-1.48 1.66 1.66 0 00-1.78.37 1.61 1.61 0 00.26 2.48zm14.6.02c.26.17.56.25.88.25v-.02a1.65 1.65 0 001.62-1.36v-.45a1.61 1.61 0 00-1.15-1.34 1.66 1.66 0 00-1.7.49 1.61 1.61 0 00.34 2.43zM28 12.7h1.73v-.03a1.11 1.11 0 001.13-1.1v-3.9h-4.72a.57.57 0 01-.4-.16.56.56 0 01-.16-.4V3.54c0-.15.06-.29.16-.4.11-.1.25-.16.4-.16h4.7a1.44 1.44 0 00-.47-.9 1.47 1.47 0 00-.97-.37h-5.56V9.5a1.64 1.64 0 01-1.63 1.61h-9.8c.52.4.88.96 1.02 1.6h9.14a2.73 2.73 0 012.72-2.2A2.79 2.79 0 0128 12.7zm2.38-6.13V4.12l-3.67-.02v2.48h3.67zM11.62 8.1c-.23.1-.47.15-.71.15-.9 0-1.89-.64-2.5-1.72-.7-1.26-.35-3.75-.3-4.03a.56.56 0 01.28-.42.57.57 0 01.5-.03c.26.12 2.58 1.15 3.3 2.42a3.1 3.1 0 01.23 2.84c.25.24.52.47.8.68a.56.56 0 01.24.6.56.56 0 01-.49.42.57.57 0 01-.43-.13c-.32-.24-.63-.5-.92-.78zM11.19 5c-.33-.58-1.3-1.2-2.05-1.6-.06.84-.06 1.97.26 2.56.26.52.7.92 1.24 1.13a16 16 0 01-1.22-1.6.56.56 0 01.37-.86.57.57 0 01.58.25c.35.53.74 1.03 1.15 1.5.03-.48-.08-.96-.33-1.38z"
    />
  </svg>
);

export default PowerTruck;
