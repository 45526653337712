import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const Recycle: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 26 25" className={className}>
    <path
      fill="currentColor"
      d="M5 15.35a.64.64 0 00.16-.6 7.34 7.34 0 013.76-8.5l-.2.4a.63.63 0 00.56.92.63.63 0 00.56-.35L11.94 3a.63.63 0 00-.29-.84L9.43 1.07 7.4.07a.65.65 0 00-.9.78c.06.16.18.3.33.37l.65.32A12.25 12.25 0 00.58 16.53a.63.63 0 00.5.43h.1a.62.62 0 00.5-.26l1.09-1.54 1.63.37a.63.63 0 00.6-.18zm-2.36-1.5a.64.64 0 00-.65.24l-.54.77a10.97 10.97 0 017.6-12.57l1.45.72-.77 1.56a8.62 8.62 0 00-6 9.52l-1.09-.25zm18.64 5.64l-2.13-.23-.66-1.22a.61.61 0 00-.47-.32.63.63 0 00-.54.18 7.35 7.35 0 01-9.35.95l.62-.04a.63.63 0 00.37-1.1.63.63 0 00-.45-.16l-4.7.3a.61.61 0 00-.44.22.62.62 0 00-.15.45l.3 4.7a.63.63 0 00.63.61h.04a.63.63 0 00.59-.67l-.04-.62a12.23 12.23 0 0016.8-2 .62.62 0 00.1-.64.63.63 0 00-.52-.41zm-9.06 4.24c-2.76 0-5.41-1.04-7.43-2.91l-.12-1.76 1.61-.1a8.6 8.6 0 0011.49.37l.45.81a.62.62 0 00.5.32l1.22.13a10.96 10.96 0 01-7.71 3.14h-.01zm13.67-10.06a.64.64 0 00-.86-.17l-.6.4A12.2 12.2 0 0014.46.77a.63.63 0 00-.64.25.63.63 0 000 .68l1 1.66-.85 1.46a.63.63 0 00.36.92 7.38 7.38 0 015.23 6.95l-.22-.32a.63.63 0 00-1.05.7l2.62 3.9a.63.63 0 00.5.29c.12 0 .24-.04.35-.1l2.09-1.37c.05-.02.1-.05.14-.09l1.71-1.14a.63.63 0 00.18-.89zm-4.27 2.1l-.97-1.46a.56.56 0 00.07-.2A8.63 8.63 0 0015.4 4.8l.66-1.13a.63.63 0 000-.63l-.4-.68A10.97 10.97 0 0123 14.83l-1.4.93z"
    />
  </svg>
);

export default Recycle;
