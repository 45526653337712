import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const Valves: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 22 22" className={className}>
    <g
      fill="none"
      stroke="currentColor"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 14.1H.5v-7h2.6m2.3 0h10.9m2.6 0h2.6v7h-2.7m-2.5 0h-11M16.7 21H12m-2 0H5.3m-2.1 0H.5v-3h2.7m2 0H10m2 0h4.6m2 0h2.9v3h-2.8" />
      <rect width="2.2" height="9.4" x="3.1" y="5.7" rx="1.1" ry="1.1" />
      <rect width="2.2" height="9.4" x="16.5" y="5.7" rx="1.1" ry="1.1" />
      <rect width="1.9" height="4.6" x="3.3" y="16.9" rx=".9" ry="1.1" />
      <rect width="1.9" height="4.6" x="10" y="16.9" rx=".9" ry="1.1" />
      <rect width="1.9" height="4.6" x="16.7" y="16.9" rx=".9" ry="1.1" />
      <path d="M11 7.2V2.5" />
      <rect
        width="2"
        height="10.1"
        x=".6"
        y="-16.1"
        rx="1"
        ry="1.1"
        transform="rotate(90)"
      />
    </g>
  </svg>
);

export default Valves;
