import React, { FunctionComponent } from "react";

type Props = React.SVGProps<SVGElement>;

const Sun: FunctionComponent<Props> = ({ className }) => (
  <svg viewBox="0 0 30 25" className={className}>
    <g fill="none" stroke="currentColor" strokeLinecap="round">
      <path d="M.6 24.4L9.1 21M8 24.5v-2.8M10.7 24.4l8.6-3.5M18.2 24.5v-2.8M20.9 24.4l8.5-3.5M28.4 24.5v-2.8" />
      <circle cx="15" cy="9" r="3.4" />
      <path d="M15 .5v4M15 .5v4M21 3l-2.7 2.8M23.6 9h-4M21 15.1l-2.7-2.7M15 17.6v-3.9M9 15.1l2.7-2.7M6.4 9h4M9 3l2.7 2.8M15 .5v4" />
    </g>
  </svg>
);

export default Sun;
